<template>
  <div>
    <b-row>
      <b-col class="mt-2"><h3>{{ fragetext }}</h3></b-col>
    </b-row>
    <b-row v-if="hinweistext">
      <b-col class="mb-2">{{ hinweistext }}</b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(opt, index) in optionen"
        :key="index"
        :xs="xs"
        :sm="sm"
        :md="md"
        :lg="lg"
        :xl="xl"
        
      >
        <b-form-radio
          v-model="selected"
          button
          button-variant="outline-dark"
          :name="`Frage_${Frage}`"
          :value="opt.value ? opt.value : opt.text"
          class="mx-1 my-1"
          >{{ opt.text }}</b-form-radio
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fragetext: "Wie wichtig ist es Dir Deine Haut zu verbessern?",
      hinweistext: undefined,
      optionen: [
        { text: "Sehr wichtig" },
        { text: "Wichtig" },
        { text: "Nicht wichtig" }
      ],
      selected: undefined,
      xs: 12,
      sm: 12,
      md: 6,
      lg: 4,
      xl: 4,
    };
  },
  props: {
    Frage: { required: true },
  },
  watch: {
    selected() {
      this.$store.commit("setAnwser", {
        Frage: this.Frage,
        Fragentext: this.fragetext,
        Antwort: this.selected,
      });
    },
  },
  beforeMount() {
      const answ = this.$store.getters.getAnwsers
      if (answ[this.Frage - 1]) {
          this.selected = answ[this.Frage - 1].Antwort
      }
  }
};
</script>